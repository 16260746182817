import React, { useState, useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Card from "../components/Card"
import Header from "../components/Header"
import Footer from "../components/Footer/Footer"
import Pagination from "./../components/Pagination"
import { BsSearch } from "react-icons/bs"
import "./AllBlogs.css"
import Seo from "../components/SEO"

const AllBlogs = ({ location }) => {
  //  const
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlog(sort: { fields: createdAt, order: DESC }) {
        nodes {
          blogSummary
          blogCategory
          title
          blogDetail {
            raw
          }
          img {
            file {
              url
            }
          }
        }
      }
      allContentfulAllBlogs {
        nodes {
          title
          description
        }
      }
      allContentfulSeo(sort: { fields: createdAt }) {
        nodes {
          title
          description
          keywords
        }
      }
    }
  `)

  // const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(5)
  const [posts, setPosts] = useState([])
  const [filterOption, setFilterOption] = useState("All")
  const [searchKeyword, setSearchKeyWord] = useState("")

  const { allContentfulBlog, allContentfulAllBlogs, allContentfulSeo } = data
  const allPosts = allContentfulBlog.nodes
  useEffect(() => {
    setPosts(allContentfulBlog.nodes)
  }, [])

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = pageNumber => setCurrentPage(pageNumber)

  // <<<<<<<<<<<<<<<<<<<<<<<<<FILTER FUNCTION>>>>>>>>>>>>>>>>>>>>>>>>>>
  const handle_filter = value => {
    setFilterOption(value)
    console.log(value)
    var filteredPosts = []
    if (value === "All") {
      filteredPosts = allPosts
    }
    for (var i = 0; i < allPosts.length; i++) {
      if (allPosts[i].blogCategory[0] === value) {
        console.log(allPosts[i].blogCategory[0])
        filteredPosts.push(allPosts[i])
      }
    }
    setPosts(filteredPosts)
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<<SEARCH FUNCTION>>>>>>>>>>>>>>>>>>>>>>>>>>
  const search_handler = () => {
    const searchedBlogs = allPosts.filter(search => {
      return (
        search.title.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        search.blogDetail.raw
          .toLowerCase()
          .includes(searchKeyword.toLowerCase())
      )
    })
    setPosts(searchedBlogs)
  }

  return (
    <div>
      <Seo
        title={allContentfulSeo.nodes[4].title}
        description={allContentfulSeo.nodes[4].description}
        keywords={allContentfulSeo.nodes[4].keywords}
      />
      <Header />
      <section className="section-padding-blog">
        <div className="container">
          <div className="row justify-content-center container">
            <div className="col-sm-12 col-md-6 col-lg-6 contact-tagline">
              <h3 className="headings-of-all">
                {allContentfulAllBlogs.nodes[0].title}
              </h3>
              <div style={{ marginBottom: "20px" }}>
                <h2 style={{ color: "#243671" }}>
                  {allContentfulAllBlogs.nodes[0].description}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="_filter_container">
          <div className="_search_section">
            <input
              onChange={e => setSearchKeyWord(e.target.value)}
              type="search"
              name="search"
              placeholder="Search"
              className="_searchInput"
              maxLength="10"
            />
            <button className="_searchBtn" onClick={() => search_handler()}>
              <BsSearch />
            </button>
          </div>
          <select
            className="_filter_list"
            name="blogs"
            id="blog"
            value={filterOption}
            onChange={e => handle_filter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="News and Insights">News and Insights</option>
            <option value="Blogs and Articles">Blogs and Articles</option>
          </select>
        </div>
        <div style={{ marginTop: "50px" }} className="container">
          <div className="row justify-content-center container">
            {currentPosts.map((v, i) => (
              <Link to={`/blog/${i}`} key={i}>
                <Card
                  img={v.img.file.url}
                  title={v.title}
                  description={v.blogSummary}
                  href={`${location.origin}/blog/${i}`}
                />
              </Link>
            ))}
          </div>
        </div>
      </section>
      {/* <<<<<<<<<<<<<<<<<<<<<<<<< PAGINATION >>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={posts.length}
          paginate={paginate}
        />
      </div>
      <Footer />
    </div>
  )
}

export default AllBlogs
